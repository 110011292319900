<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import NewPayee from './components/new-payee.vue'

const router = useRouter()

const redirect = computed(() => {
  return router.currentRoute.query?.back
    ? { name: router.currentRoute.query?.back }
    : router.currentRoute.meta?.back ?? { name: 'payments-payees' }
})

const title = computed(() => {
  return router.currentRoute.meta?.title || 'Add Payee'
})
</script>

<template>
  <base-aside-page :title="title" :back="redirect" :no-padding="true">
    <new-payee />
  </base-aside-page>
</template>
